
import { defineComponent, onMounted, reactive, toRaw, watch } from "vue"

export default defineComponent({
    inheritAttrs: false,

    emits: ["change"],

    props: {
        id: [String, Number],

        type: String,

        edit: {
            type: Boolean,
            default: false,
        },

        pointable: Boolean,

        newest: Boolean,

        options: {
            type: Array,
            default: () => []
        },

        required: Boolean,
    },

    setup(props, { emit }) {
        const state = reactive({
            options: props.options || []
        })

        watch(() => state.options, function () {
            emit("change", toRaw({ ...props, options: state.options }))
        })

        onMounted(function () {
            if (props.options?.length === 0) {
                const options = new Array(5).fill(null)
                state.options = options.map(function (item, index) {
                    return {
                        label: `${index + 1}`,
                        points: index + 1
                    }
                })
            }
        })

        return {
            state: state
        }
    }
})
