<template>
  <form :class="$attrs.class">
    Mostrar
    <select
      :value="size"
      class="form-select"
      @change="onChange"
    >
      <option>10</option>
      <option>30</option>
      <option>50</option>
      <option>100</option>
    </select>
    registros
  </form>
</template>

<script>
  export default {
    name: "PageSizeSelect",
    props: ['size'],
    emits: ['change'],
    methods: {
      onChange(e) {
        this.$emit('change', parseInt(e.target.value))
      },
    },
  }
</script>
