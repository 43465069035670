<template>
  <div class="row mb-5">
    <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
      <h1 class="m-0 text-dark ttU">{{title}}</h1>
    </div>
    <div class="col-xs-12 col-sm-6">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Heading',
    props: ['title'],
  }
</script>
