
import { computed, defineComponent, onBeforeMount, reactive } from "vue"

import service from "@/services/formBuilderService"
import moment from "moment"

import Loading from "@/components/Loading.vue"

export default defineComponent({
    components: {
        Loading
    },

    setup() {
        const state = reactive({
            loading: true,
            list: [],
            search: "",
        })

        async function fetchData() {
            const list = await service.getSurveys()

            state.list = list.map(function (item: {updated_at: string; date?: string}) {
                item.date = moment(item.updated_at).format("DD/MM/YYYY hh:mm A")

                return item
            })
        }

        onBeforeMount(async function () {
            state.loading = true

            try {
                await fetchData()
            } finally {
                state.loading = false
            }
        })

        async function deleteSurvey(id: string | number) {
            state.loading = true

            try {
                const res = await service.deleteSurvey(id)

                if (res.success) {
                    await fetchData()
                }
            } finally {
                state.loading = false
            }
        }

        const filtered = computed(function () {
            const search = new RegExp(state.search, "i")

            return state.list.filter(function (survey) {
                return (
                    search.test(survey.title) ||
                    search.test(survey.date)
                )
            })
        })

        return {
            state,
            filtered,
            deleteSurvey,
        }
    }
})
