<template>
     <LayoutDefault>
             <Loading :active="loading" />
     </LayoutDefault>
</template>
<script>
import userService from "@/services/userService";
import LayoutDefault from "@/layout/LayoutAdmin";
export default {
    name: 'CoachesDelete',
    components: {LayoutDefault},
    data() {
      return {
          loading: false
      }
    },
    async beforeMount(){
        this.loading = true;
        try{ 
           await userService.deleteUser(this.$route.params.coach_id)
        }catch(e){
            console.log(e)
        }
        this.loading = false;
        this.$router.go(-1);
    }
}
</script>