
import { defineComponent } from "vue"

export default defineComponent({
    emits: ["change", "remove"],

    props: {
        id: [String, Number],

        type: String,

        label: {
            type: String,
            default: ""
        }
    },
})
