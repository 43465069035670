
import { defineComponent, onMounted, reactive, ref } from "vue"
import { useRouter, useRoute } from "vue-router"

import SurveyBuilder from "@/components/SurveyBuilder.vue"
import Loading from "@/components/Loading.vue"

import service from "@/services/formBuilderService"

export default defineComponent({
    components: {
        SurveyBuilder,
        Loading,
    },

    setup() {
        const loading = ref(false)

        const state = reactive({
            id: -1,
            title: "",
            type: "EVALUATION",
            questions: [],
        })

        const { push: navigate } = useRouter()
        const { params } = useRoute()

        async function fetchData() {
            const survey = await service.getSurveyId(params.id.toString())
            const questions = await service.getQuestionsId(params.id.toString())
            
            state.questions = await Promise.all(
                questions
                    .sort((a: any, b: any) => a.order - b.order)
                    .map(async function (question: any) {
                        const element: any = {
                            id: question.id,
                            label: question.label,
                            type: question.question_type,
                            pointable: !!question.apply_points || false,
                            required: !!question.required || false,
                        }

                        if (/select|checkbox|score/i.test(element.type)) {
                            const options = await service.getOptionsId(question.id)
                            
                            element.options = options.map(function (option: any) {
                                return {
                                    id: option.id,
                                    label: option.label,
                                    name: option.name,
                                    points: option.points,
                                }
                            })
                        }

                        return element
                    })
            )

            state.id = survey.id
            state.title = survey.title
            state.type = survey.survey_type
        }

        onMounted(async function () {
            loading.value = true

            try {
                await fetchData()
            }

            catch (err) {
                console.error(err)
            }

            finally {
                loading.value = false
            }
        })

        async function save({ title, type, form }) {
            loading.value = true

            try {
                await service.putSurveyId(state.id, {
                    title: title,
                    type: type
                })

                const request = form.map(async function (element: any, index: number) {
                    const item = {
                        label: element.label,
                        question_type: element.type,
                        survey_id: state.id,
                        apply_points: element.pointable || false,
                        required: element.required || false,
                        order: index,
                    }

                    let question: any = {}

                    if (element.newest === true) {
                        question = await service.createQuestion(item)
                    }

                    else {
                        question = await service.putQuestionId(element.id, item)
                    }

                    if (Array.isArray(element.options)) {
                        const request = element.options.map(async function (option) {
                            option.question_id = question.id || element.id
                            option.name = option.label?.replace(/\s/g, "_")

                            if (option.id) {
                                await service.putOptionId(option.id, option)
                            }

                            else {
                                await service.createOption(option)
                            }
                        })

                        await Promise.all(request)
                    }
                })

                await Promise.all(request)
                await navigate({ name: "Surveys" })
            }

            catch (err) {
                console.error(err)
            }

            finally {
                loading.value = false
            }
        }

        return {
            save,
            loading,
            state,
        }
    }
})
