import config from '@/config/config';
import Axios from 'axios';

export default new class FormBuilderService {
    private config = config.getConfig();
    baseURL = this.config.apiUrl
    token =  localStorage.getItem('__token');

    // ? ------------------- GET -------------------
    async getSurveys() {
        try {
            return (await Axios.get(`${this.baseURL}/survey`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getVisits(options: {page: number; size?: number; search?: string}) {
      const response = await Axios.get(
        `${this.baseURL}/visits?page=${options?.page || 1}&pageSize=${options?.size||20}&search=${encodeURIComponent(options.search||'')}`,
        {headers: { 'Authorization': `Bearer ${this.token}`}},
      )

      return response.data
    }

    async getCoaches() {
        try {
          const response = await Axios.post(`${this.config.apiUrl}/get-users-role`, {role: "coach"}, {
              headers: {'Authorization': `Bearer ${this.token}`},
          })

            return response.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getVisit(id: any) {
        try {
            return (await Axios.get(`${this.config.apiUrl}/visit/${id}`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async addVisit(form: object = {}) {
        try {
            return (await Axios.post(`${this.config.apiUrl}/visit`,
            form, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }    
    }

    async delete(id: any) {
        try {
            return (await Axios.delete(`${this.config.apiUrl}/visit/${id}`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async updateVisit(id: number, visit: any) {
        try {
            const res = await Axios.put(`${this.config.apiUrl}/visit/${id}`, visit, {
                headers: {
                    authorization: `Bearer ${this.token}`
                }
            })

            return res.data
        }

        catch (err) {
            console.error(err)
        }
    }
}
