<template>
   <LayoutDefault>
         <Loading :active="loading" />
         <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <router-link
                :to="{ name: 'Coaches' }"
                class="custom-back m-0 ttU"
              >
                <i class="fas fa-arrow-circle-left"></i> Atrás
              </router-link>
            </div>
            <!-- /.col -->
          </div>
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">{{edit ? 'Editar': 'Crear'}}</h1>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
      
        <div class="container-fluid">
          <div class="form">
            <div class="form-group flex">
              <form @submit="saveData">
                  <label for="exampleFormControlSelect1"
                    >Nombres</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Agregar comentario"
                    name="name"
                    autocomplete="off"
                    v-model="form.name"
                    required
                  />
                  <label for="exampleFormControlSelect1"
                    >Apellidos</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Agregar comentario"
                    name="title"
                    autocomplete="off"
                    v-model="form.lastName"
                    required
                  />
                  <label for="exampleFormControlSelect1"
                    >Email</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Agregar comentario"
                    name="title"
                    autocomplete="off"
                    v-model="form.email"
                    required
                  />
                  <label for="exampleFormControlSelect1"
                    >Teléfono</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Agregar comentario"
                    name="title"
                    autocomplete="off"
                    v-model="form.phone"
                    required
                  />
                  <button
                      type="submit"
                      class="button btn btn-default btn-default-invert"
                    >
                  Guardar
                </button>
                <!--  <button
                      @click="saveData"
                      class="button btn btn-default btn-default-invert"
                    >
                  prueba
                </button> -->
              </form>
            </div>
          </div>
        </div>
      </section> 
<!-- 
      <section class="content">
        <div class="container-fluid">
          <div class="form">
            <div class="inputs">
              <div class="form-group">
                <p><b>ID Ticket:</b> {{ ticket.id }}</p>
                <p><b>Area:</b> {{ ticket.area }}</p>
                <p><b>Asunto:</b> {{ ticket.subject }}</p>
                <p>
                  <b>Solicitud:</b>
                  {{
                    (Array.isArray(JSON.parse(ticket.detail))
                      ? JSON.parse(ticket.detail)
                      : [])[0]?.comment
                  }}
                </p>
                <p><b>Creado por:</b> {{ ticket.user?.email }}</p>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1"
                  >Estatus del ticket</label
                >
                <select
                  class="form-control"
                  v-model="statusSelected"
                  id="exampleFormControlSelect1"
                >
                  <option
                    v-for="(status, index) in statusArray"
                    :value="status.toLowerCase()"
                    :key="index"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>

              <div id="chat" class="chat">
                <div
                  class="chat-message"
                  :class="el.userId === 0 ? 'admin' : 'user'"
                  v-for="(el, index) in Array.isArray(JSON.parse(ticket.detail))
                    ? JSON.parse(ticket.detail)
                    : []"
                  :key="index"
                >
                  <span>{{ el.comment }}</span>
                  <span class="date">{{ formatDate(el.created_at) }}</span>
                </div>
                <div
                  class="chat-message"
                  :class="el.userId === 0 ? 'admin' : 'user'"
                  v-for="(el, index) in newDetail"
                  :key="index"
                >
                  <span>{{ el.comment }}</span>
                  <span class="date">{{ formatDate(el.created_at) }}</span>
                </div>
              </div>
              <div class="form-group flex">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Agregar comentario"
                  name="title"
                  v-model="newComment"
                  @keyup.enter="addNewComment()"
                  autocomplete="off"
                />
                <button @click="addNewComment()" type="button">
                  <i class="fas fa-arrow-circle-up"></i>
                </button>
              </div>
              <button
                type="button"
                class="button btn btn-default btn-default-invert"
                @click="saveData()"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
         /.container-fluid -->
     <!--  </section> --> 
      <!-- /.content -->
    </div>
   </LayoutDefault>

</template>
<script>
import LayoutDefault from "@/layout/LayoutAdmin";
import Loading from "@/components/Loading";
import userService from "@/services/userService";
import Toast from "@/components/Toast"; 

export default {
    name: 'Coaches',
    components: {LayoutDefault,Loading, Toast},
    data(){
      return {
        loading: false,
        edit: false,
        form: {},
        toastType: "",
        toastActive: true,
        toastMessage: ""
      }
    },
    async beforeMount(){
      this.edit = (this.$route.params.coach_id)  ? true: false;
      if(this.edit){
         this.loading = true;
          try{
            this.form = await userService.getOneUser(this.$route.params.coach_id);
            this.loading= false;
          }catch(e){
            this.loading= false;
          }
      }
    },
    methods: {
      async saveData(e){
         this.loading = true;
        e.preventDefault();e.preventDefault();
        let resp = {}
        try{
          if(this.edit){
            resp =  await userService.updateUser(this.$route.params.coach_id,this.form);
          }else{
            resp =  await userService.createUser(this.form);
          }
          if(resp['error']){
            this.toastType = "error";
            this.toastMessage = "Fallo al guardar";
            this.toastActive = true;
          }else{
            this.toastType = "success";
            this.toastMessage = "Guardado correctamente";
            this.toastActive = true; 
            if(!this.edit) this.form = {};
          }
        }catch(e){
          console.log(e)
        }
         this.loading = false;
      }
    }
}
</script>

<style scoped>
  .content{
    display: flex;
    justify-content: center;
  }
  .container-fluid{
    width: 80%;
  }
  .button{
    margin: 50px 0;
  }
  label {
    margin-top: 10px;
  }
</style>