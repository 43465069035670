import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-wrapper p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_SurveyBuilder = _resolveComponent("SurveyBuilder")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Loading, { active: _ctx.loading }, null, 8, ["active"]),
        _createVNode(_component_SurveyBuilder, { onSave: _ctx.save }, null, 8, ["onSave"])
      ])
    ]),
    _: 1
  }))
}