
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue"

import formBuilderService from "@/services/formBuilderService"

type Types = "TEXT" | "SELECT" | "CHECKBOX" | "CAMERA" | "SCORE"

const components = {
    "TEXT": "survey-text",
    "SELECT": "survey-select",
    "CHECKBOX": "survey-checkbox",
    "CAMERA": "survey-camera",
    "SCORE": "survey-score",
    "EMPLOYEE": "survey-employee",
    "TITLE": "survey-title"
}

import Toast from "./Toast.vue"
import Loading from "./Loading.vue"

import SurveyComponent from "./SurveyComponent.vue"

import SurveyText from "./SurveyComponents/SurveyText.vue"
import SurveySelect from "./SurveyComponents/SurveySelect.vue"
import SurveyCheckbox from "./SurveyComponents/SurveyCheckbox.vue"
import SurveyCamera from "./SurveyComponents/SurveyCamera.vue"
import SurveyScore from "./SurveyComponents/SurveyScore.vue"
import SurveyEmployee from "./SurveyComponents/SurveyEmployee.vue"
import SurveyTitle from "./SurveyComponents/SurveyTitle.vue"

export default defineComponent({
    inheritAttrs: false,

    components: {
        Toast,
        Loading,
        SurveyComponent,

        SurveyText,
        SurveySelect,
        SurveyCheckbox,
        SurveyCamera,
        SurveyScore,
        SurveyEmployee,
        SurveyTitle,
    },

    props: {
        title: {
            type: String,
            default: "",
        },

        type: {
            type: String,
            default: "",
        },

        questions: {
            type: Array,
            default: () => []
        },

        edit: {
            type: Boolean,
            default: false,
        }
    },

    setup(props, { emit }) {
        const loading = ref(true)

        const state = reactive({
            title: props.title,
            form: props.questions as any[],
        })

        function addField(type: Types) {
            state.form.unshift({
                id: Date.now(),
                type: type,
                newest: true,
                pointable: true,
                required: true,
            })
        }

        async function removeField(index: number, item: any) {
            if (props.edit && !item.newest) {
                loading.value = true
                
                try {
                    await formBuilderService.deleteQuestion(item.id)
                }

                catch (err) {
                    console.error(err)
                }

                finally {
                    loading.value = false
                }
            }

            state.form = state.form.filter((_, key) => key !== index)
        }

        function selectComponent(type: Types) {
            return components[type] || "survey-text"
        }

        function startDrag(event: DragEvent, index: number) {
            event.dataTransfer.dropEffect = "move"
            event.dataTransfer.effectAllowed = "move"
            event.dataTransfer.setData("item-index", index.toString())
        }

        function endDrag(event: DragEvent, to: number) {
            const from = parseInt(event.dataTransfer.getData("item-index"))

            if (typeof from === "number") {
                state.form.splice(to, 0, state.form.splice(from, 1)[0])
            }
        }

        // element types

        const elements = ref([])

        onBeforeMount(async function () {
            loading.value = true

            try {
                elements.value = await formBuilderService.getTypesQuestions()
            }

            finally {
                loading.value = false
            }
        })

        // toast

        const toast = reactive({
            message: "",
            show: false,
            type: ""
        })

        function send() {
            if (state.form.length > 0) {
                emit("save", state)
            }

            else {
                toast.message = "Debe crear al menos un campo"
                toast.show = true
                toast.type = "error"

                console.warn(toast)
            }
        }

        const disabledButton = computed(function () {
            return state.form.length === 0 || state.title === ""
        })

        // type

        const types = ref([])

        onBeforeMount(async function () {
            loading.value = true

            try {
                types.value = await formBuilderService.getTypeSurvey()
            }

            finally {
                loading.value = false
            }
        })

        return {
            state,
            send,
            toast,
            types,
            loading,
            elements,
            addField,
            removeField,
            selectComponent,
            disabledButton,

            startDrag,
            endDrag,
        }
    }
})
