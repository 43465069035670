
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"

import SurveyBuilder from "@/components/SurveyBuilder.vue"
import Loading from "@/components/Loading.vue"

import service from "@/services/formBuilderService"

export default defineComponent({
    components: {
        SurveyBuilder,
        Loading,
    },

    setup() {
        const loading = ref(false)

        const { push: navigate } = useRouter()

        async function save({ form, title }) {
            loading.value = true

            try {
                const survey = await service.createSurvey({ title, type: "EVALUATION" })
                
                const request = form.map(async function (element: any, index: number) {
                    const item = {
                        label: element.label,
                        question_type: element.type,
                        survey_id: survey.id,
                        apply_points: element.pointable || false,
                        required: element.required || false,
                        order: index,
                    }

                    const question = await service.createQuestion(item)

                    if (Array.isArray(element.options)) {
                        const request = element.options.map(async function (option) {
                            option.question_id = question.id
                            option.name = option.label?.replace(/\s/g, "_")

                            await service.createOption(option)
                        })

                        await Promise.all(request)
                    }
                })

                await Promise.all(request)
                await navigate({ name: "Surveys" })
            }

            catch (err) {
                console.error(err)
            }

            finally {
                loading.value = false
            }
        }

        return {
            save,
            loading,
        }
    }
})
