
import { defineComponent, reactive, toRaw, watch } from "vue"

export default defineComponent({
    emits: ["change", "remove"],

    props: {
        id: [String, Number],

        type: String,

        newest: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: ""
        },

        pointable: Boolean,

        options: Array,

        required: Boolean,
    },

    setup(props, { emit }) {
        const input = reactive({ ...props })

        watch(input, function () {
            emit("change", toRaw(input))
        })

        return {
            input
        }
    }
})
