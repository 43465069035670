
import { defineComponent, reactive, ref, toRaw, watch } from "vue"

import service from "@/services/formBuilderService"

export default defineComponent({
    inheritAttrs: false,

    emits: ["change", "remove"],

    props: {
        id: [String, Number],

        type: String,

        edit: {
            type: Boolean,
            default: false,
        },

        pointable: Boolean,

        newest: Boolean,

        options: {
            type: Array,
            default: () => [{ label: "" }]
        },

        required: Boolean,
    },

    setup(props, { emit }) {
        const loading = ref(false)
        let options = reactive(props.options as any[] || [])

        watch(options, function () {
            emit("change", toRaw({ ...props, options: options }))
            console.log("HELO")
        })

        function addOption() {
            options.push({ label: "" })
        }

        async function removeOption(index: number, id: string | number) {
            if (options.length > 1) {
                loading.value = true
                
                try {
                    if (props.edit) {
                        await service.deleteOption(id)
                    }

                    options = options.filter((_, key) => key !== index)
                }

                catch (err) {
                    console.error(err)
                }

                finally {
                    loading.value = false
                }
            }
        }

        return {
            list: options,
            loading,
            
            addOption,
            removeOption,
        }
    }
})
