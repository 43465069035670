<template>
  <LayoutDefault>
  <!-- Content Wrapper. Contains page content -->
  <Loading :active="loading" />
  <div class="content-wrapper" >
    <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
        />

    <!-- Content Header (Page header) -->
    <div class="content-header mt-2">
      <div class="container-fluid">
        <Heading title="Visitas">
            <button
              type="button"
              class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
              @click="redirecTo()"
            >
              Agregar
            </button>
        </Heading>
        <form class="row form-inline justify-content-between" @submit.prevent="getVisits">
          <PageSizeSelect
              class="col-xs-12 col-sm-3"
              :size="pageSize"
              @change="setPageSize"
              />

          <div class="ml-3 form-group">
            <label class="mr-2">Buscar:</label>
              <input
                class="form-control"
                placeholder="PBL"
                v-model="search"
                @change="getVisits"
              />
          </div>
        </form>
      </div>
    </div>

    <section class="content">
      <div class="boxBody">
        <table class="table table-bordered table-striped">
          <thead>
            <th>Id</th>
            <th>PBL</th>
            <th>Fecha Visita</th>
            <th>EDS</th><!-- 
            <th>Encuesta</th> -->
            <th>Estado</th>
            <th>Acciones</th>
          </thead>
          <tbody>

            <tr v-for="(visit, index) in visits.data" :key="index">
              <td class="low-contrast">{{visit.id.toLocaleString()}}</td>
              <td>{{visit.station?.pbl || 'No disponible'}}</td>
              <td>{{formatDate(visit.date_visit)}}</td>
              <td class="low-contrast">{{visit?.station?.service_station}}</td>
              <td>
                <span :class="{
                  'badge': true,
                  'bg-success': visit?.finished,
                  'bg-danger': !visit?.finished,
                }">
                  {{visit?.finished ? 'Terminado' : 'Pendiente'}}
                </span>
              </td>
              <!--  <td>Encuestas #1</td> -->
              <td class="actions">
                <button
                  type="button"
                  @click="redirecTo(visit.id)"
                  aria-label="Editar"
                  style="outline: none; border: 0; background: none"
                >
                  <img
                    src="../assets/img/icon_edit_blue.svg"
                    class="iconBtn"
                    alt="icon remove"
                    style="width: 20px"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination
            :pagination="visits"
            @set-page="setPage"
            />
      </div>

    </section>
  </div>

  <Modal name="example"> This es a example </Modal>

  </LayoutDefault>
</template>
<script>
  import LayoutDefault from "../layout/LayoutAdmin";
  // import Toast from "../components/Toast";
  import Heading from "../components/Heading";
  import Loading from "../components/Loading";
  import visitsService from "@/services/visitsService"; 
  import {formatDate} from '../lib/date'
  import Pagination from "../components/Pagination";
  import PageSizeSelect from "../components/PageSizeSelect";

  export default {
    name:'visits',
    components: {
      LayoutDefault,
      Loading,
      Heading,
      Pagination,
      PageSizeSelect,
    },
    data: () => ({
      loading: false,
      page: 1,
      pageSize: 10,
      visits: [],
      search: "",
      toastType: "",
      toastActive: false,
      toastMessage: "",
    }),
    mounted() {
      return this.getVisits()
    },
    created () {
      /* window.addEventListener('scroll', this.handleScroll); */
    },
    methods: {
      async getVisits() {
        this.loading = true

        try {
          const resp = await visitsService.getVisits({
            search: this.search,
            page: this.page,
            size: this.pageSize,
          });
          this.visits = resp;
        } finally {
          this.loading = false
        }
      },

      async setPageSize(size) {
        this.page = 1
        this.pageSize = size 
        this.getVisits()
      },

      async setPage(page) {
        this.page = page
        this.getVisits()
      },

      redirecTo(idVisit = null) {
        this.$router.push(`/dashboard/create-or-edit-visit${idVisit ? `?idVisit=${idVisit}` : ''}`)
        console.log('redirectig...', idVisit)
      },

      async deleteVisit(idVisit) {
        const comfirm = confirm("Desea eliminar este elemento");

        if(!comfirm) {
          return
        }

        this.loading = true;
        const resp = await visitsService.delete(idVisit);

        if (!resp['error']) {
          this.toastMessage  = "Elemento eliminado exitosamente";
          this.toastActive = true;
          this.toastType = "success";
          await  this.getVisits();
        } else {
          console.log('esto se ejecuto')
          this.toastActive = true;
          this.toastMessage = 'Se ha presentado un error, intenta nuevamente';
          this.toastType = "error";
        }

        this.loading = false;
      },

      handleScroll(){
        /* const page = window.scrollY / 500;
            this.getVisits(page) */
      },

      formatDate(d) {
        if (!d) {
          return ''
        }

        return formatDate(d)
      },
    }
  }
</script>

<style lang="scss">
.continerCrud {
  width: 100%;
  padding: 20px;
  .boxTitle {
    padding: 10px;
    background-color: #BDBDBD;
    display: flex;
    border-top-left-radius:12px;
    border-top-right-radius:12px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    h2 {
      color: #fff;
      margin: 0;
      font-weight: 300;
      line-height: 25px;
      margin-top: 7px;
    }

    section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      input {
        margin: 0 5px;
        border: none;
        border-radius: 50px;
        /* font-family: 'Proxima Nova'; */
        display: flex;
        align-items: center;
        padding: 5px 10px;
        &:focus {
          outline-color: #fff;
        }
      }

      button {
        margin: 0 5px;
        border: none;
        border-radius: 50px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* font-family: 'Proxima Nova'; */
        font-weight: 300;
        width: 130px;
        height: 32px;
        .iconBtn {
          width: 17px;
          // height: 17px;
        margin-right: 7px;
        }
        p {
          margin-top: 2px;
          // line-height: 16px;
        margin-bottom: 0;
        }
        &:first-child {
          background-color: #1E22AA;
        }
        &:last-child {
          background-color: #FF6900;
        }
        &:focus {
          outline-color: #fff;
        }
      }
    }
  }
  .boxBody {
    padding: 10px 10px 40px 10px;
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px;

    border: 1px solid #bdbdbd;

    table {
      width:100%;
      /* font-family: 'Proxima Nova'; */
      thead {
        border-bottom: 1px solid #bdbdbd;
        th {
          color: #666;
          padding: 10px 0;
        }
      }
      tbody {
        td {
          font-weight: 300;
          color: #666;
          padding: 10px 0;
          &.actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              width: 17px;
              margin: 0 5px;
              cursor:pointer;
            }
          }
        }
      }
    }
  }
}
</style>
