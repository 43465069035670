<template>
    <LayoutDefault>
            
          <div class="content-wrapper news">
            <!-- Content Header (Page header) -->
          <div class="content-header mt-2">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
                  <h1 class="m-0 text-dark ttU">Entrenadores</h1>
                </div>
                <!-- /.col -->
                <div class="col-xs-12 col-sm-6">
                 
                  <input
                    type="file"
                    id="file"
                    ref="file"
              
                    style="display: none"
                  />
                  <button
                    type="button"
                    class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
                    onclick="window.location.href='sellers/create'"
                  >
                     Crear Nuevo
                  </button>

                <!--   <a
                    type="button"
                    class="btn btn-default float-right ml-2 mb-2 mb-md-0"
                  
                  >
                    
                    Crear Nuevo
                  </a> -->
                  <!-- <button
                    type="button"
                    class="btn btn-default float-right ml-2 mb-2 mb-md-0"
                  >
                    Crear
                  </button> -->
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
          </div>
             <!-- Main content -->
                <section class="content">
                    <div class="container-fluid">
                      <p v-if="loading">Cargando...</p>
                    <div class="card card--table">
                        <!-- <div class="card-header">
                
                        </div> -->
                        <!-- /.card-header -->
                        <div class="card-body">
                        <table id="coaches" class="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>Acción</th>
                                <th>Nombres</th>
                                <th>Apellidos</th>
                                <th>Email</th>
                                <th>Teléfono</th>
                            </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot>
                            <tr>
                                <th>Acción</th>
                                <th>Nombres</th>
                                <th>Apellidos</th>
                                <th>Email</th>
                                <th>Teléfono</th>
                            </tr>
                            </tfoot>
                        </table>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    </div>
                    <!-- /.container-fluid -->
                </section>
          </div>
    </LayoutDefault>
     
</template>
<script>
import $ from "jquery";
import "datatables.net";
import userService from "@/services/userService";
import LayoutDefault from "@/layout/LayoutAdmin";
export default {
    name: 'Coaches',
    components: {LayoutDefault},
    data() {
      return {
        users : [],
        edit: false,
        loading: true
      }
    },
    async mounted() {
    this.loading = true;
    this.table = $("#coaches").DataTable({
      pageLength: 50,
      language: {
        url: "https://cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json",
      }
    });
    this.users = await userService.getUsersRole('seller'); // 1
    this.loading = false;
    console.log('---> ',this.users)
    await this.addDataToTable(this.users);
    this.loading = false;
  },
  methods: {

    addDataToTable(data) {
  
      this.table.clear();
      data.forEach((el) => {
        this.table.row
          .add([
            `<a href='coaches/edit/${el.id}' >Editar</a> <a href='coaches/delete/${el.id}'  onclick="return confirm('¿Estas seguro que quieres eliminar este usuario?')">Eliminar</a>`,
            el.name,
            el.lastName,
            el.email,
            el.phone,
            el.address
          ])
          .draw(false);
      });
    },
  }
}
</script>
