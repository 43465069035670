<template>
    <div class="mt-3 mb-2">
        <input type="file" disabled>
    </div>
</template>

<style lang="scss">
.survey-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
</style>