<template>
    <input type="text" class="input" placeholder="Escriba una respuesta" disabled>
</template>

<style lang="scss">
.survey-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
</style>