<template>
  <div class="container-fluid">
    <div class="row justify-content-between align-items-center mt-5">

      <div col="col-xs-12 col-sm-6">
        <p class="text-secondary">
        Mostrando registros del <b>{{ pagination.from }}</b> al
        <b>{{ pagination.to }}</b> de un total de
        <b>{{ (pagination?.total || 0).toLocaleString() }}</b> registros.
        </p>
      </div>

      <nav
          col="col-xs-12 col-sm-6"
          aria-label="Paginación de las estaciones de servicio"
      >
        <ul class="pagination">
          <li
            class="page-item"
            :class="{active: link.active, disabled: !link.url}"
            :key="link.label"
            v-for="link of pagination.links"
          >
            <a
              class="page-link"
              @click.prevent="pageClicked(link)"
              :href="link.url || '#'"
              v-html="link.label"
            />
          </li>
        </ul>
      </nav>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',

    props: ['pagination'],

    emits: ['setPage'],

    methods: {
      pageClicked(link) {
        if (!link) {
          return;
        }

        const url = new URL(link.url)
        const page = url.searchParams.get('page')

        if (!page) {
          return;
        }

        this.$emit('setPage', parseInt(page, 10))
      },
    },
  }
</script>
