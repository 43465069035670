import config from '@/config/config';
import Axios from 'axios';

export default new class FormBuilderService {
    private config = config.getConfig();
    baseURL = this.config.apiUrl
    token =  localStorage.getItem('__token');

    // ? ------------------- GET -------------------
    async getSurveys() {
        try {
            return (await Axios.get(`${this.baseURL}/survey`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getTypeSurvey() {
        try {
            return (await Axios.get(`${this.baseURL}/survey-types`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getTypesQuestions() {
        try {
            return (await Axios.get(`${this.baseURL}/question-types`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getSurveyId(id: string | number) {
        try {
            return (await Axios.get(`${this.baseURL}/survey/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getQuestionsId(surveyId: string | number) {
        try {
            return (await Axios.get(`${this.baseURL}/question/${surveyId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getOptionsId(questionId: string | number) {
        try {
            return (await Axios.get(`${this.baseURL}/option/${questionId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    // ? ------------------- POST -------------------
    async createSurvey(survey: object = {}) {
        try {
            return (await Axios.post(`${this.config.apiUrl}/survey`,
                survey, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async createQuestion(question: object = {}) {
        try {
            return (await Axios.post(`${this.config.apiUrl}/question`,
                question, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async createOption(option: object = {}) {
        try {
            const res = await Axios.post(`${this.config.apiUrl}/option`,
                option, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            if (res.data.error) {
                console.error(res.data)
            }

            return res.data
        }
        
        catch (e) {
            console.error(e)
        }
    }

    // ? ------------------- PUT -------------------
    async putSurveyId(idSurvey: string | number, survey: any = {}) {
        try {
            const res = await Axios.put(`${this.baseURL}/survey/${idSurvey}`,
                survey, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            if (res.data.error) {
                console.error(res.data)
            }
        }
        
        catch (e) {
            console.error(e)
        }
    }

    async putQuestionId(idQuestion: string | number, question: any = {}) {
        try {
            const res = await Axios.put(`${this.baseURL}/question/${idQuestion}`,
                question, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            if (res.data.error) {
                console.error(res.data)
            }

            return res.data
        }
        
        catch (e) {
            console.error(e)
        }
    }

    async putOptionId(idOption: string | number, option: any = {}) {
        try {
            const res = await Axios.put(`${this.baseURL}/option/${idOption}`,
                option, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            if (res.data.error) {
                console.error(res.data)
            }
        }
        
        catch (e) {
            console.error(e)
        }
    }

    // ? ------------------- DELETE -------------------
    async deleteSurvey(idSurvey: string | number) {
        try {
            return (await Axios.delete(`${this.baseURL}/survey/${idSurvey}`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async deleteQuestion(idQuestion: string | number) {
        try {
            const res = await Axios.delete(`${this.baseURL}/question/${idQuestion}`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            if (res.data.error) {
                console.error(res.data)
            }
        }
        
        catch (e) {
            console.error(e)
        }
    }

    async deleteOption(idOption: string | number) {
        try {
            const res = await Axios.delete(`${this.baseURL}/option/${idOption}`, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })

            if (res.data.error) {
                console.error(res.data)
            }
        }
        
        catch (e) {
            console.error(e)
        }
    }
}