import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content-wrapper p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_SurveyBuilder = _resolveComponent("SurveyBuilder")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Loading, { active: _ctx.loading }, null, 8, ["active"]),
        (_ctx.state.id !== -1)
          ? (_openBlock(), _createBlock(_component_SurveyBuilder, _mergeProps({ key: 0 }, _ctx.state, {
              edit: true,
              onSave: _ctx.save
            }), null, 16, ["onSave"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}